// 已有题库页面的导入题库
const ExamFileUrl = process.env.VUE_APP_SERVER_URL + '/api/bank/saveQuestions'
// 学生管理页面的导入学生信息
const StuFileUrl = process.env.VUE_APP_SERVER_URL + '/api/student/importStudent'
// 补录题库页面添加附件答案
const ExcelName = '/answer/uploadFiles'

export default {
  ExamFileUrl,
  StuFileUrl,
  ExcelName
}
