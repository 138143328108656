<template>
  <div class="addanswer">
    <!-- <el-button href="#answers" @click="$router.push('answers')">查看答题情况</el-button> -->
    <el-container style="height: 100%; border: 1px solid #eee">

      <el-container>

        <el-main>
          <div class="bigDiv">
            <el-row :gutter="20">
              <!-- <el-col :xs="18" :sm="10" :md="6" :lg="5">
                <el-form label-width="80px">
                  <el-form-item label="题目名称:">
                    <el-input v-model="realname" placeholder="请输入题名" clearable></el-input>
                  </el-form-item>
                </el-form>
              </el-col> -->
              <el-col :xs="18" :sm="10" :md="8" :lg="5">
                <el-form label-width="80px">
                  <el-form-item label="科目名称:">
                    <el-select v-model="cid" placeholder="请选择科目名称" clearable filterable>
                      <el-option v-for="item in cname" :key="item.cid" :label="item.ccourse"
                        :value="item.ccourse">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </el-col>
              <!-- <el-col :xs="18" :sm="10" :md="8" :lg="8">
                <el-date-picker v-model="value2" type="datetimerange"
                  :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期" align="right">
                </el-date-picker>
              </el-col> -->
              <!-- <el-col :xs="18" :sm="6" :md="4" :lg="2">
                <el-button type="primary" @click="goSearch">查询</el-button>
              </el-col> -->
              <!-- <el-col :xs="18" :sm="6" :md="4" :lg="3">
                <el-button type="primary" icon="el-icon-plus" @click="addSubject">录入答案
                </el-button>
              </el-col> -->
            </el-row>

            <el-table :data="lists" style="width: 100%" :stripe="true" :border="true"
              :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column prop="c_course" label="科目名称" width="190">
              </el-table-column>
              <el-table-column prop="uq_question_name" label="题目">
              </el-table-column>
              <el-table-column prop="uq_question_options" label="选项">
              </el-table-column>
              <!-- <el-table-column prop="sc_answer_status" label="题型">
              </el-table-column> -->
              <el-table-column label="操作" width="140">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" icon="el-icon-plus"
                    @click="handleShow(scope.$index, scope.row)">录入答案</el-button>
                  <!-- <el-button size="mini" type="danger"
                    @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
                </template>
              </el-table-column>
              <div slot="empty">
                <p>
                  <img :src="imgUrl" />
                </p>
              </div>
            </el-table>
            <el-pagination :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
              @current-change="handleCurrentChange" :page-sizes="[100, 200, 300, 400]"
              @size-change="handleSizeChange" :total="allLength || 0" align="right">
            </el-pagination>
          </div>
        </el-main>
      </el-container>
      <!-- 新增科目弹窗 -->
      <el-dialog title="新增科目" :visible.sync="MessageBox" showCancelButton=true
        showConfirmButton=true>
        <el-tabs v-model="activeName2" type="card" @tab-click="handleClick" style="height: 50vh">
          <el-tab-pane label="选择题、匹配题" name="first">
            <div>题目：</div>
            <div style="margin-left: 20px;">{{formLabelAlign.uq_question_name}}</div>
            <br>
            <span style="margin-top: 20px;">选项：</span>
            <div style="margin-left: 20px;">{{formLabelAlign.uq_question_options}}</div>
            <br>
            <span>答案：</span>
            <el-input type="textarea" v-model="ruleForm.desc" rows="6"></el-input>
          </el-tab-pane>
          <el-tab-pane label="填空题、判断题" name="second">
            <div>题目：</div>
            <div style="margin-left: 20px;">{{formLabelAlign.uq_question_name}}</div>
            <br>
            <span>答案：</span>
            <el-input type="textarea" v-model="ruleForm.desc" rows="6"></el-input>
          </el-tab-pane>
          <el-tab-pane label="问答题" name="third">
            <div>题目：</div>
            <div style="margin-left: 20px;">{{formLabelAlign.uq_question_name}}</div>
            <br>
            <span>答案：</span>
            <el-input type="textarea" v-model="ruleForm.desc" rows="6"></el-input>
          </el-tab-pane>
          <el-tab-pane label="附件题" name="fourth">
            <div>题目：</div>
            <div style="margin-left: 20px;">{{formLabelAlign.uq_question_name}}</div>
            <br>
            <el-upload class="upload-demo" :action="ExcelName" :on-preview="handlePreview"
              :on-change="handleChange" :file-list="fileList3" limit:10 :on-progress="showFileName"
              multiple>
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>

          </el-tab-pane>
        </el-tabs>
        <el-button type="primary" @click="addAnswer">提 交</el-button>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { parseTime } from '@/common/date.js' // 在组件中引用date.js,时间戳
import commonApi from '@/api/commonApi.js'
import ExcelName from '@/common/fileUrl.js'

export default {
  data () {
    // name: 'Home'
    return {
      tableHeight: window.innerHeight - 300,
      ExcelName: ExcelName.ExcelName, // 录入附件的url地址
      fileList3: [],
      ruleForm: {
        descbox: '',
        desc: ''
      },
      realname: '', // 姓名
      username: '', // 学号
      fileNametext: '', // 附件名字
      MessageBox: false, // 新增未插入题目
      tabindex: 1, // 保存tabs的值 对应提交数据的类型（如选择题、判断题）
      activeName2: 'first', // 补录点击的东西
      cname: [], // 科目名称
      cid: '', // 选中的科目名称
      cids: '', // 选中的科目编号
      tname: [], // 根据科目名称查询任务
      sc_task: '', // 选中的任务名称
      // fileList: [], // 存放文件的数组
      showmessage: false, // 查看弹窗
      labelPosition: 'right',
      formLabelAlign: {},
      dstatus: [{
        name: '单选题',
        answerStatus: 0
      }, {
        name: '多选题',
        answerStatus: 1
      }, {
        name: '填空题',
        answerStatus: 2
      }, {
        name: '附件题',
        answerStatus: -1
      }], // 答题状态
      answerStatus: '', // 选中的答题状态
      lists: [
        // {
        //   sc_realname: '张三怎么去小花家', sc_username: '', sc_answer_status: '逻辑题', c_name: '思想道德'
        // },
        // {
        //   sc_realname: '张三怎么去小花家', sc_username: '', sc_answer_status: '逻辑题', c_name: '思想道德'
        // },
        // {
        //   sc_realname: '张三怎么去小花家', sc_username: '', sc_answer_status: '逻辑题', c_name: '思想道德'
        // }
      ], // 获取到的数据
      indexPage: 1, // 当前页数，默认第一页
      pageSize: 100, // 每页显示条数
      allLength: 0, // 查询到的数据总条数
      allPageNumber: 0, // 查询到的数据总页数
      imgUrl: require('../assets/Lark20201216-174717.png') // 图片地址
      // pickerOptions: {
      //   shortcuts: [{
      //     text: '最近一周',
      //     onClick (picker) {
      //       const end = new Date()
      //       const start = new Date()
      //       start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      //       picker.$emit('pick', [start, end])
      //     }
      //   }, {
      //     text: '最近一个月',
      //     onClick (picker) {
      //       const end = new Date()
      //       const start = new Date()
      //       start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      //       picker.$emit('pick', [start, end])
      //     }
      //   }, {
      //     text: '最近三个月',
      //     onClick (picker) {
      //       const end = new Date()
      //       const start = new Date()
      //       start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      //       picker.$emit('pick', [start, end])
      //     }
      //   }]
      // },
      // value2: ''
    }
  },
  mounted () {
    this.allCourseName()
    this.goSearch()
  },
  methods: {
    // 录入答案的确定
    addAnswer: function () {
      if (this.tabindex === 4) {
        for (let k = 0; k < this.fileList3.length; k++) {
          if (k === this.fileList3.length - 1) {
            this.fileNametext = this.fileNametext + this.fileList3[k].name
          } else {
            this.fileNametext = this.fileNametext + this.fileList3[k].name + '\n'
          }
        }
      }
      const data = {
        answer: this.ruleForm.desc,
        choice: this.ruleForm.descbox,
        qaAppendixList: this.fileNametext,
        qbCourseId: this.formLabelAlign.c_id,
        qbQuestionName: this.formLabelAlign.uq_question_name,
        questionType: this.tabindex,
        uqId: this.formLabelAlign.uq_id
      }
      if (this.ruleForm.desc !== '' || this.fileNametext !== '') {
        commonApi.insertTask(data).then((response) => {
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.goSearch()
        }).catch((response) => {
          console.log(response)
        })
      }
      this.ruleForm.desc = ''
      this.ruleForm.descbox = ''
      this.fileNametext = ''
      this.tabindex = 1
      this.MessageBox = false
    },
    // tabs 标签页
    handleClick (tab, event) {
      tab.index++
      this.tabindex = tab.index
      this.ruleForm.desc = ''
    },
    // 查看文件上传名字
    showFileName: function (file, fileList) {
      this.fileNametext = fileList.name
    },
    // 查看信息
    handleShow: function (index, rows) {
      // this.showmessage = true
      this.MessageBox = true
      this.formLabelAlign = rows
    },
    // 删除题库
    handleDelete: function (index, rows) {
    },
    // 导入题库exl表
    handleChange (file, fileList) {
      this.fileList3 = fileList.slice(-3)
    },
    // 附件题上传
    handlePreview (file) {
    },
    // 侧边栏展开
    handleOpen: function (key, keyPath) {
    },
    // 侧边栏关闭
    handleClose: function (key, keyPath) {
    },
    // 新增科目
    addSubject () {
      this.MessageBox = true
    },
    // 查询科目名称
    allCourseName: function () {
      const AllName = { ccourse: '全部' }
      commonApi.selectCourse().then((response) => {
        this.cname = response
        this.cname.unshift(AllName)
      }).catch((response) => {
        console.log(response)
      })
    },
    // 查询数据
    goSearch: function () {
      const data = {
        current: this.indexPage, // 当前页
        size: this.pageSize // 每页数据条数
      }
      for (let i = 0; i < this.cname.length; i++) {
        if (this.cid === this.cname[i].ccourse) {
          data.courseId = this.cname[i].cid
        }
      }
      // if (this.answerStatus || this.answerStatus === 0) data.answerStatus = this.answerStatus
      // if (this.realname) data.realname = this.realname
      // if (this.sc_task) data.task = this.sc_task
      // if (this.username) data.username = this.username
      // if (this.cid || this.cid === 0) data.courseId = this.cid
      commonApi.listReQuestion(data).then((response) => {
        this.lists = response.records || []
        this.allLength = Number(response.total)
        this.allPageNumber = this.allLength / this.pageSize || 1
      }).catch((response) => {
        console.log(response)
      })
    },
    // 页数切换
    handleCurrentChange (cpage) {
      this.indexPage = cpage
      this.goSearch()
    },
    // 每页条数切换
    handleSizeChange (val) {
      // 获取每页数据条数
      this.pageSize = val
      // 触发查询操作
      this.goSearch()
    },
    // 时间戳转化声明
    parseTime: parseTime
  },
  watch: {
    // 监听科目名称下拉选框切换时发送请求获取到任务名称,并且触发查询操作
    cid (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    },

    // 监听答题状态改变时触发查询操作
    answerStatus (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    },

    // 监听任务名称改变时触发查询操作
    sc_task (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    }
  }
}
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.el-aside {
  color: #333;
}
.disrow {
  display: flex;
  flex-direction: row;
}
</style>
